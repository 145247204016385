import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IProps } from '../../../types';
import { RequestUtil } from '../../../utils';
import { CmsConstants } from './cms-constants';
import CMSContext from './cms.context';
import { CmsPropertyCreateDto } from './dto/cms-property-create.dto';
import { CmsPropertyDto } from '../../../hooks/cms/dto/cms-property.dto';

interface IListingCMSFormNavbarSectionContainerResource {
  url: string;
}

interface ICMSFormNavbarSectionContainer extends IProps {
  resource?: IListingCMSFormNavbarSectionContainerResource;
}

const CMSFormNavbarSection = ({
  resource,
}: ICMSFormNavbarSectionContainer) => {
  const {data} = useContext(CMSContext);
  const formRef = useRef(null);
  const inputFileRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // states for form
  const [navbarBrandName, setNavbarBrandName] = useState('');
  const [navbarBrandMediaFileType, setNavbarBrandMediaFileType] = useState('');
  const [navbarBrandMediaFileName, setNavbarBrandMediaFileName] = useState('');
  const [navbarBrandMediaFileBase64, setNavbarBrandMediaFileBase64] = useState('');

  useEffect(() => {
    setNavbarBrandName(data?.find(
        p => p.property ===
          CmsConstants.PROPERTY_NAVBAR_BRAND_NAME)?.value as string ??
      '');

    const media = data?.find(
      p => p.property === CmsConstants.PROPERTY_NAVBAR_BRAND_MEDIA) as json;
    setNavbarBrandMediaFileType(media?.media?.mediaType ?? '');
    setNavbarBrandMediaFileName(media?.media?.fileName ?? '');
    setNavbarBrandMediaFileBase64(media?.value?.main ?? '');
  }, [data]);

  const submit = async (evt: any) => {
    evt.preventDefault();
    if (formRef.current) {
      const form = formRef.current as HTMLFormElement;
      if (!form.checkValidity()) {
        form.classList.add('was-validated');
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(true);

    const payload: CmsPropertyCreateDto = {
      'properties': [
        {
          'property': CmsConstants.PROPERTY_NAVBAR_BRAND_NAME,
          'value': navbarBrandName ?? '',
        },
      ],
    };
    if (navbarBrandMediaFileType && navbarBrandMediaFileName && navbarBrandMediaFileBase64) {
      payload.properties.push({
        'property': CmsConstants.PROPERTY_NAVBAR_BRAND_MEDIA,
        'media': {
          'mediaType': navbarBrandMediaFileType,
          'fileName': navbarBrandMediaFileName,
          'base64': navbarBrandMediaFileBase64,
        },
      });
    } else {
      payload.properties.push({
        'property': CmsConstants.PROPERTY_NAVBAR_BRAND_MEDIA,
      });
    }
    RequestUtil.post(resource?.url ?? '', payload, {}, () => CmsPropertyDto).
      then(ressponse => {
        console.log(ressponse);
      }).
      catch(error => console.error(error)).
      finally(() => setIsLoading(false));
  };

  const onNavbarBrandMediaChange = (evt: any) => {
    const $el = evt.currentTarget;
    const [file] = $el.files;
    if (!file) {
      throw new Error(`'There isn't any image file loaded`);
    }
    setNavbarBrandMediaFileBase64(URL.createObjectURL(file));
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      setNavbarBrandMediaFileType(file.type);
      setNavbarBrandMediaFileName(file.name);
      setNavbarBrandMediaFileBase64(reader.result as string);
    });
    reader.readAsDataURL(file);
  };

  const onNavbarBrandMediaRemove = (evt: any) => {
    setNavbarBrandMediaFileType('');
    setNavbarBrandMediaFileName('');
    setNavbarBrandMediaFileBase64('');
  };

  return (
    <div className="d-flex w-100 h-100">
      <div id="mod-restaurant-main"
           className="w-100 position-relative">
        <div className="d-flex flex-column align-items-center ps-3 pe-3">
          <div
            className="container-fluid p-0 overflow-auto">
            <form ref={formRef}
                  className="needs-validation"
                  noValidate={true}
                  autoComplete="off"
                  onSubmit={submit}>
              <div className="form-floating mb-3">
                <input id="cms.navbar.brand.name"
                       value={navbarBrandName}
                       onChange={(el) => setNavbarBrandName(
                         el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="Brand Name"
                       autoComplete="nope"
                       required={true}/>
                <label htmlFor={'cms.navbar.brand.name'}
                       className="form-label">Brand Name
                </label>
              </div>
              <div className="d-flex justify-content-center w-100">
                <div className="d-flex justify-content-center w-100 h-200--px mb-5 border rounded-3">
                  <img className="h-100"
                       alt="brand media"
                       src={navbarBrandMediaFileBase64}/>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label"
                       htmlFor="cms.navbar.brand.media">Upload the Background
                  Image
                </label>
                <div className="input-group">
                  <input ref={inputFileRef}
                         id="cms.navbar.brand.media"
                         type="file"
                         className="form-control form-control-lg"
                         placeholder="Background Image"
                         required={true}
                         onChange={onNavbarBrandMediaChange}
                  />
                  <button className="btn btn-outline-secondary"
                          type="button"
                          onClick={onNavbarBrandMediaRemove}>Remove File
                  </button>
                </div>
              </div>
              <button type="submit"
                      className="btn btn-primary">Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CMSFormNavbarSection };
