import { Expose, Type } from 'class-transformer';
import { ImageDto } from '../../../../types/image.dto';

export class ProductDto {
  @Expose()
  id!: string;

  @Expose()
  uuid!: string;

  @Expose()
  name!: string;

  @Expose()
  slug?: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  enabled!: boolean;

  @Expose()
  stock?: number;

  @Expose()
  price?: number;

  @Expose()
  @Type(() => ImageDto)
  images?: ImageDto;

  @Expose()
  createdAt!: Date;

  @Expose()
  updatedAt!: Date;
}
