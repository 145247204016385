import { CacheUtil, Pipes, RequestUtil } from '../../../utils';
import { ProductDto, ShopProductCreateDto, ShopProductUpdateDto } from './dto';
import { ShopProductMediaCreateDto } from './dto/shop-product-media-create.dto';

class ShopProductActions {

  static async save(data: ProductDto) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/products`;
    if (data.uuid) {
      return RequestUtil.patch(
        `${url}/${data.uuid}`,
        Pipes.transform(ShopProductUpdateDto, data),
        {},
        () => ProductDto,
      );
    }
    return RequestUtil.post(
      url,
      Pipes.transform(ShopProductCreateDto, data),
      {},
      () => ProductDto,
    );
  }

  static async saveMedia(productUUID: string, data: FormData) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/products/${productUUID}/file`;

    return RequestUtil.patch(url, data, {}, () => ProductDto,
    );
  }
}

export { ShopProductActions };
