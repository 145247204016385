import { Expose } from 'class-transformer';
import { CmsPropertyMediaDto } from './cms-property-media.dto';

export class CmsPropertyDto {
  @Expose()
  property!: string;

  @Expose()
  value?: string | json;

  @Expose()
  media?: CmsPropertyMediaDto;
}
