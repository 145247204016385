import { Expose } from 'class-transformer';

export class ImageDto {
  @Expose()
  thumb!: string;

  @Expose()
  main!: string;

  @Expose()
  details!: Array<string>;
}
