import React, {
  useContext, useEffect,
  useRef,
  useState,
} from 'react';
import { IProps } from '../../../types';
import { RequestUtil } from '../../../utils';
import { CmsConstants } from './cms-constants';
import CMSContext from './cms.context';
import { CmsPropertyCreateDto } from './dto/cms-property-create.dto';
import { CmsPropertyDto } from '../../../hooks/cms/dto/cms-property.dto';

interface IListingCMSFormHeroSectionContainerResource {
  url: string;
}

interface ICMSFormHeroSectionContainer extends IProps {
  resource?: IListingCMSFormHeroSectionContainerResource;
}

const CMSFormHeroSection = ({
  resource,
}: ICMSFormHeroSectionContainer) => {
  const {data} = useContext(CMSContext);
  const formRef = useRef(null);
  const inputFileRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // states for form
  const [heroTitle, setHeroTitle] = useState('');
  const [heroSubtitle, setHeroSubtitle] = useState('');
  const [heroBgColor, setHeroBgColor] = useState('');
  const [heroBgMedia, setHeroBgMedia] = useState('');
  const [heroBgMediaFileType, setHeroBgMediaFileType] = useState('');
  const [heroBgMediaFileName, setHeroBgMediaFileName] = useState('');
  const [heroBgMediaFileBase64, setHeroBgMediaFileBase64] = useState('');

  useEffect(() => {
    setHeroTitle(data?.find(
      p => p.property ===
        CmsConstants.PROPERTY_HERO_STORE_TITLE)?.value as string ?? '');
    setHeroSubtitle(data?.find(
        p => p.property ===
          CmsConstants.PROPERTY_HERO_STORE_SUBTITLE)?.value as string ??
      '');
    setHeroBgColor(data?.find(
        p => p.property ===
          CmsConstants.PROPERTY_HERO_STORE_BG_COLOR)?.value as string ??
      '');

    const media = data?.find(
      p => p.property === CmsConstants.PROPERTY_HERO_STORE_MEDIA) as json;
    setHeroBgMediaFileType(media?.media?.mediaType ?? '');
    setHeroBgMediaFileName(media?.media?.fileName ?? '');
    setHeroBgMediaFileBase64(media?.value?.main ?? '');
    setHeroBgMedia(media?.value?.main ?? '');
  }, [data]);

  const submit = async (evt: any) => {
    evt.preventDefault();
    if (formRef.current) {
      const form = formRef.current as HTMLFormElement;
      if (!form.checkValidity()) {
        form.classList.add('was-validated');
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(true);

    const payload: CmsPropertyCreateDto = {
      'properties': [
        {
          'property': CmsConstants.PROPERTY_HERO_STORE_TITLE,
          'value': heroTitle ?? '',
        },
        {
          'property': CmsConstants.PROPERTY_HERO_STORE_SUBTITLE,
          'value': heroSubtitle ?? '',
        },
        {
          'property': CmsConstants.PROPERTY_HERO_STORE_BG_COLOR,
          'value': heroBgColor ?? '',
        },
      ],
    };
    if (heroBgMediaFileType && heroBgMediaFileName && heroBgMediaFileBase64) {
      payload.properties.push({
        'property': CmsConstants.PROPERTY_HERO_STORE_MEDIA,
        'media': {
          'mediaType': heroBgMediaFileType,
          'fileName': heroBgMediaFileName,
          'base64': heroBgMediaFileBase64,
        },
      });
    } else {
      payload.properties.push({
        'property': CmsConstants.PROPERTY_HERO_STORE_MEDIA,
      });
    }
    RequestUtil.post(resource?.url ?? '', payload, {}, () => CmsPropertyDto).
      then(ressponse => {
        console.log(ressponse);
      }).
      catch(error => console.error(error)).
      finally(() => setIsLoading(false));
  };

  const onHeroBgMediaChange = (evt: any) => {
    const $el = evt.currentTarget;
    const [file] = $el.files;
    if (!file) {
      throw new Error(`'There isn't any image file loaded`);
    }
    setHeroBgMedia(URL.createObjectURL(file));
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      setHeroBgMediaFileType(file.type);
      setHeroBgMediaFileName(file.name);
      setHeroBgMediaFileBase64(reader.result as string);
    });
    reader.readAsDataURL(file);
  };

  const onHeroBgMediaRemove = (evt: any) => {
    setHeroBgMedia('');
    setHeroBgMediaFileType('');
    setHeroBgMediaFileName('');
    setHeroBgMediaFileBase64('');
  };

  return (
    <div className="d-flex w-100 h-100">
      <div id="mod-restaurant-main"
           className="w-100 position-relative">
        <div className="d-flex flex-column align-items-center ps-3 pe-3">
          <div
            className="container-fluid p-0 overflow-auto">
            <form ref={formRef}
                  className="needs-validation"
                  noValidate={true}
                  autoComplete="off"
                  onSubmit={submit}>
              <div className="form-floating mb-3">
                <input id="cms.hero.title"
                       value={heroTitle}
                       onChange={(el) => setHeroTitle(el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="Hero Title"
                       autoComplete="nope"
                       required={true}/>
                <label htmlFor={'cms.hero.title'}
                       className="form-label">Hero Title
                </label>
              </div>
              <div className="form-floating mb-3">
                <input id="cms.hero.subtitle"
                       value={heroSubtitle}
                       onChange={(el) => setHeroSubtitle(
                         el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="Hero Title"
                       autoComplete="nope"
                />
                <label htmlFor={'cms.hero.subtitle'}
                       className="form-label">Hero Subtitle
                </label>
              </div>
              <div className="form-floating mb-3">
                <input id="cms.hero.bg.color"
                       value={heroBgColor}
                       onChange={(el) => setHeroBgColor(el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="Hero Title"
                       autoComplete="nope"
                />
                <label htmlFor={'cms.hero.bg.color'}
                       className="form-label">Hero Background Color
                </label>
              </div>
              <div className="d-flex justify-content-center w-100">
                <div className="d-flex justify-content-center w-100 h-200--px mb-5 border rounded-3">
                  <img className="h-100"
                       alt="hero bg media"
                       src={heroBgMedia}/>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label"
                       htmlFor="cms.hero.bg.media">Upload the Background Image
                </label>
                <div className="input-group">
                  <input ref={inputFileRef}
                         id="cms.hero.bg.media"
                         type="file"
                         className="form-control form-control-lg"
                         placeholder="Background Image"
                         required={true}
                         onChange={onHeroBgMediaChange}
                  />
                  <button className="btn btn-outline-secondary"
                          type="button"
                          onClick={onHeroBgMediaRemove}>Remove File
                  </button>
                </div>
              </div>
              <button type="submit"
                      className="btn btn-primary">Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CMSFormHeroSection };
